import type { AutomaticOrder, DeliveryInfo, Stop, StopActivity } from './types'

export * from './types'

export function isStopDone(stop: {
  done: Stop['done']
  deliveryInfo: Partial<Pick<DeliveryInfo, 'attempted'>> | null
}) {
  return stop.deliveryInfo?.attempted ?? stop.done
}

export function isStopFlexible(stop: Pick<Stop, 'optimizationPlacement'>) {
  return (
    stop.optimizationPlacement == null ||
    stop.optimizationPlacement === 'flexible'
  )
}

export function hasFailedAttempt(
  deliveryInfo: Partial<Pick<DeliveryInfo, 'attempted' | 'succeeded'>> | null,
) {
  return hasAttempt(deliveryInfo) && Boolean(deliveryInfo?.succeeded === false)
}

export function hasSuccessfulAttempt(
  deliveryInfo: Partial<Pick<DeliveryInfo, 'attempted' | 'succeeded'>> | null,
) {
  return hasAttempt(deliveryInfo) && Boolean(deliveryInfo?.succeeded)
}

export function hasAttempt(
  deliveryInfo: Partial<Pick<DeliveryInfo, 'attempted'>> | null,
) {
  return Boolean(deliveryInfo?.attempted)
}

/**
 * @deprecated use `getStopOptimisationOrder` instead
 */
export function isStopASAP(stop: Pick<Stop, 'priority' | 'optimizationOrder'>) {
  return getStopOptimizationOrder(stop).type === 'first'
}

export function getStopOptimizationOrder(
  stop: Pick<Stop, 'priority' | 'optimizationOrder'>,
): AutomaticOrder {
  if (stop.optimizationOrder != null) {
    // Always prefer optimizationOrder if present
    return stop.optimizationOrder
  }

  if (stop.priority === 4) {
    return { type: 'first' }
  }

  return { type: 'default' }
}

/**
 * @deprecated use `getStopOptimizationOrder` instead
 */
export function getStopOptimisationOrder(
  stop: Pick<Stop, 'priority' | 'optimizationOrder'>,
): AutomaticOrder {
  return getStopOptimizationOrder(stop)
}

export function hasTimeRange(
  stop: Pick<Stop, 'timeWindowEarliestTime' | 'timeWindowLatestTime'>,
) {
  return Boolean(
    stop.timeWindowEarliestTime != null &&
      stop.timeWindowLatestTime != null &&
      stop.timeWindowEarliestTime >= 0 &&
      stop.timeWindowLatestTime >= 0,
  )
}

export function hasArriveAfter(stop: Pick<Stop, 'timeWindowEarliestTime'>) {
  return Boolean(
    stop.timeWindowEarliestTime != null && stop.timeWindowEarliestTime >= 0,
  )
}

export function hasArriveBefore(stop: Pick<Stop, 'timeWindowLatestTime'>) {
  return Boolean(
    stop.timeWindowLatestTime != null && stop.timeWindowLatestTime >= 0,
  )
}

export function getStopActivity(stop: Pick<Stop, 'activity'>): StopActivity {
  return stop.activity ?? 'delivery'
}

/**
 * Returns all the successfully uploaded proof of delivery photos URLs
 */
export function getProofOfDeliveryPhotos(deliveryInfo: DeliveryInfo): string[] {
  const { photos, photoUrls, photoUrl } = deliveryInfo

  const filteredPhotos = photos?.filter(
    (photo) => photo.status === 'uploaded' && photo.type === 'proof',
  )

  if (filteredPhotos && filteredPhotos.length > 0) {
    return filteredPhotos?.map((photo) => photo.url)
  }

  const fallbackPhoto = photoUrl ? [photoUrl] : []
  const successfulPhotos = photoUrls ?? fallbackPhoto

  return successfulPhotos
}

/**
 * Returns the successfully uploaded signature photo URL
 */
export function getSignaturePhoto(deliveryInfo: DeliveryInfo): string | null {
  const { photos, signatureUrl } = deliveryInfo

  const filteredPhotos = photos?.filter(
    (photo) => photo.status === 'uploaded' && photo.type === 'signature',
  )

  if (filteredPhotos && filteredPhotos.length > 0) {
    return filteredPhotos[0].url
  }

  return signatureUrl
}

export function isStopDelivery(stop: Pick<Stop, 'type' | 'activity'>): boolean {
  if (stop.type !== 'stop') return false

  return getStopActivity(stop) === 'delivery'
}

export function isStopPickup(stop: Pick<Stop, 'type' | 'activity'>): boolean {
  if (stop.type !== 'stop') return false

  return getStopActivity(stop) === 'pickup'
}

export function isStopSkipped(stop: Pick<Stop, 'skippedReason'>): boolean {
  return stop.skippedReason > 0
}

/**
 * Returns true if the given stop has been marked as the next stop since the last optimization.
 *
 * This stop may not actually be the next stop if another stop in the route was marked as
 * the next stop by the user after this.
 */
export function isMarkedNextStop(
  stop: Pick<Stop, 'nextStopClickedTime'>,
): boolean {
  return stop.nextStopClickedTime ? stop.nextStopClickedTime > 0 : false
}
